import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Alert } from './components/Alert';
import { YouTube } from './components/YouTube';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "using-the-events-calendar"
    }}>{`Using the Events Calendar`}</h1>
    <p>{`All you need to know to get the most out of our events calendar.`}</p>
    <h2 {...{
      "id": "browsing--searching"
    }}>{`Browsing & Searching`}</h2>
    <ol>
      <li parentName="ol">{`The Carousel on the homepage is filled with Featured events that you can scroll through`}</li>
      <li parentName="ol">{`Use the location and category filter options to help sort events`}</li>
      <li parentName="ol">{`Use the Calendar to see the events on a particular date`}</li>
      <li parentName="ol">{`Use the search bar to search for particular events`}</li>
    </ol>
    <h3 {...{
      "id": "watch-the-video"
    }}>{`Watch The Video`}</h3>
    <YouTube mdxType="YouTube"><iframe src="//www.youtube.com/embed/7R69uqdSSRo" frameBorder="0" allowFullScreen style={{
        "position": "absolute",
        "top": "0",
        "left": "0",
        "width": "100%",
        "height": "100%"
      }}></iframe></YouTube>
    <h2 {...{
      "id": "make-a-booking"
    }}>{`Make a Booking`}</h2>
    <ol>
      <li parentName="ol">{`Select the event`}</li>
      <li parentName="ol">{`If you're not already logged in enter your membership details`}</li>
      <li parentName="ol">{`You should see your first name and last name, if an age is required please fill this in`}</li>
      <li parentName="ol">{`If booking for more than one person select '+ person', you will need to change the details for the additional person`}</li>
      <li parentName="ol">{`Select 'Book Now'`}</li>
      <li parentName="ol">{`A message will appear if you're successful`}</li>
    </ol>
    <h3 {...{
      "id": "watch-the-video-1"
    }}>{`Watch The Video`}</h3>
    <YouTube mdxType="YouTube"><iframe src="//www.youtube.com/embed/XZakgiWh9wI" frameBorder="0" allowFullScreen style={{
        "position": "absolute",
        "top": "0",
        "left": "0",
        "width": "100%",
        "height": "100%"
      }}></iframe></YouTube>
    <h2 {...{
      "id": "my-events"
    }}>{`My Events`}</h2>
    <ol>
      <li parentName="ol">{`Select 'My Events' at the top right of the screen, if not already logged in enter membership details`}</li>
      <li parentName="ol">{`To cancel your place in an event select 'Cancel Booking' and follow the prompts`}</li>
      <li parentName="ol">{`See the details of an event by clicking the event title`}</li>
      <li parentName="ol">{`Rate and provide feedback for an event by selecting past events and selecting a star rating`}</li>
      <li parentName="ol">{`If you wish to provide further feedback, you can do so now`}</li>
    </ol>
    <h3 {...{
      "id": "watch-the-video-2"
    }}>{`Watch The Video`}</h3>
    <YouTube mdxType="YouTube"><iframe src="//www.youtube.com/embed/cgv2XvIUjU4" frameBorder="0" allowFullScreen style={{
        "position": "absolute",
        "top": "0",
        "left": "0",
        "width": "100%",
        "height": "100%"
      }}></iframe></YouTube>
    <h2 {...{
      "id": "join-a-waiting-list"
    }}>{`Join a Waiting List`}</h2>
    <p>{`When an event is fully booked, join its waiting list to be notified if space becomes available. You'll have a certain number of hours to claim your places before they're offered to the next waiting in queue. This functionality is completely automated.`}</p>
    <ol>
      <li parentName="ol">{`Select the event you want to join the waiting list for`}</li>
      <li parentName="ol">{`If not logged in enter your membership details`}</li>
      <li parentName="ol">{`Enter your 'Email Address' and the 'Number of Places' needed for the waiting list`}</li>
      <li parentName="ol">{`Select 'Join Waiting List'`}</li>
      <li parentName="ol">{`You will then receive your confirmation`}</li>
    </ol>
    <h3 {...{
      "id": "watch-the-video-3"
    }}>{`Watch The Video`}</h3>
    <YouTube mdxType="YouTube"><iframe src="//www.youtube.com/embed/Rx-jdYa8n6g" frameBorder="0" allowFullScreen style={{
        "position": "absolute",
        "top": "0",
        "left": "0",
        "width": "100%",
        "height": "100%"
      }}></iframe></YouTube>
    <h2 {...{
      "id": "add-event-to-device-calendar"
    }}>{`Add Event to Device Calendar`}</h2>
    <ol>
      <li parentName="ol">{`Select the event you want to add to your device calendar`}</li>
      <li parentName="ol">{`Select 'Add to Calendar'`}</li>
      <li parentName="ol">{`This will download an ICS file that can be imported to a device calendar`}</li>
    </ol>
    <h3 {...{
      "id": "watch-the-video-4"
    }}>{`Watch The Video`}</h3>
    <YouTube mdxType="YouTube"><iframe src="//www.youtube.com/embed/VJLNGgysFSo" frameBorder="0" allowFullScreen style={{
        "position": "absolute",
        "top": "0",
        "left": "0",
        "width": "100%",
        "height": "100%"
      }}></iframe></YouTube>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      